/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/4b28e4ca9443cf96-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/dd5dc49b6a6fba84-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/0df4fb79790f0c1e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/c47a672499cc40a7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/4bcb60b63075eb7f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_b5d75b';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_b5d75b';src: local("Arial");ascent-override: 85.79%;descent-override: 22.25%;line-gap-override: 0.00%;size-adjust: 112.83%
}.__className_b5d75b {font-family: '__Montserrat_b5d75b', '__Montserrat_Fallback_b5d75b'
}.__variable_b5d75b {--font-montserrat: '__Montserrat_b5d75b', '__Montserrat_Fallback_b5d75b'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7c8e65d116921a73-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3997e6acac256061-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4c1af336e7263201-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a16bd18fba4cc08b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3c1e9baca472ab39-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5ceb519f9791c49e-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/337e7fb4bdfdb5bb-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d8ed79d95e80ddd3-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/567bf971d37239a9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fc20ddfd7920f8ee-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4ed56d36dc266006-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6bf2266754cfd806-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/aedbf204de1b560c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7fc261e10d1d2f86-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/de6eee123b7fa90d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1c0be997ceaf2dea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f0a3508c8ea9c325-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/e082a7245b91ef44-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/679c9177a049f60f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f65eabe2ce43767a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7fdc69c9a72f9a8b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fadfc70ae883dfe2-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/82e1fd7b35a49992-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c8c4108e3745c4d0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ebcfcee65b8ce8b7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/cf15560d6081599a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7556183f6f8aa478-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/66591429dfa006ea-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/de3b0906cdc42fd5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f12b8ce654ceecb7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/dca8dd988d8dde4b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/47dfd5ed404e7168-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7f4eea1b634203dc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/66eb08c7b614ae3d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cf6229eebbec82de-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3777648edf466e83-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/24088f6ff9ddf730-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5a63b8bb9e5f62d8-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d99ea5b801ed0485-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/eadaede43a49f133-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/460cc2d56061a0d1-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d09a673ca4a65de9-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2a2818883356d910-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/e7b65a35f9fbc27a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9ce942d6cb5b17c3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/18327ac0844bb7ae-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/11a55774657315ee-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f16667bf3e4d4e55-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cf94ada03c904e03-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cormorant_Garamond_486a62';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/82d44042442a5b55-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Cormorant_Garamond_Fallback_486a62';src: local("Times New Roman");ascent-override: 95.27%;descent-override: 29.59%;line-gap-override: 0.00%;size-adjust: 96.98%
}.__className_486a62 {font-family: '__Cormorant_Garamond_486a62', '__Cormorant_Garamond_Fallback_486a62'
}.__variable_486a62 {--font-cormorant: '__Cormorant_Garamond_486a62', '__Cormorant_Garamond_Fallback_486a62'
}

/* vietnamese */
@font-face {
  font-family: '__Allura_85bde0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ca5879911011010f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Allura_85bde0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5a805d6f00cf3de7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Allura_85bde0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6588bcc2d50d7444-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Allura_Fallback_85bde0';src: local("Arial");ascent-override: 105.51%;descent-override: 59.35%;line-gap-override: 0.00%;size-adjust: 75.82%
}.__className_85bde0 {font-family: '__Allura_85bde0', '__Allura_Fallback_85bde0';font-weight: 400;font-style: normal
}.__variable_85bde0 {--font-allura: '__Allura_85bde0', '__Allura_Fallback_85bde0'
}

